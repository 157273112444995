import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import debounce from 'lodash.debounce';
import moment from 'moment-js';
import { AuthContext,  } from './AuthContext';
import './ProductList.css';

export const ProductList = (props) => {
    const [state, setState] = useState({ page:0, totalPages:0, files: [], selectedCategory: null, filter: '' });
    const { user, logout } = useContext(AuthContext)
    const debouncedUpdateProducts = debounce(filter => updateProducts(state.selectedCategory, filter), 300);

    const onSelectedCategoryChanged = category => {
        updateProducts(category, state.filter);
    };

    const onFilterChanged = event => {
        debouncedUpdateProducts(event.target.value);
    }

    const updateProducts = (category, filter, page) => {
        setState({ ...state, files: [], productListLoading: true })
        axios.get("pagedjson", {
            params: {
                category: category.value,
                filter: filter,
                token: user.token,
                page: page ? page : 1
            }
        }).then(({data}) => {
            setState({ ...state, page: data.totalPages == 0 ? 0 : data.page, totalPages: data.totalPages, files: data.files, selectedCategory: category, filter: filter, productListLoading: false });
        });
    }

    const onLogout = () => {
        logout();
    }

    useEffect(() => {
        var category = null;
        if (user.settings.categories.length > 0 ) {
            category = user.settings.categories[0]
        }

        updateProducts(category, '')
    }, user.settings.categories)

    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i)*10)/10 + ' ' + sizes[i];
     }

    return (
            <div>
                <Form className="productlist-form">
                    <Container fluid>
                        <Row className="productlist-row">
                        <Col xl={2} sm={6} xs={12}>
                            <Select classNamePrefix="react-select" options={user.settings.categories} value={state.selectedCategory} onChange={onSelectedCategoryChanged} placeholder="Velg kategori" />
                        </Col>
                        <Col xl={2} sm={6} xs={12}><Input type="text" onChange={onFilterChanged} placeholder="Søk på produkt" /></Col>
                        { state.totalPages > 1 &&
                        <Col>
                            <Pagination>
                                <PaginationItem disabled={state.page <= 1}>
                                    <PaginationLink previous href="#" onClick={event => {event.preventDefault(); updateProducts(state.selectedCategory, state.filter, state.page-1)}}/>
                                </PaginationItem>

                                <PaginationItem disabled>
                                    <PaginationLink href="#">{state.page} / {state.totalPages}</PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={state.page == state.totalPages}>
                                    <PaginationLink next href="#" onClick={event => {event.preventDefault(); updateProducts(state.selectedCategory, state.filter, state.page+1)}}/>
                                </PaginationItem>
                            </Pagination>
                        </Col>}
                        <Col className="productlist-logout">
                            <a href="#" onClick={onLogout}>Logg ut</a>
                        </Col>
                        </Row>
                        { state.productListLoading && <div className="text-center">
                            <div className="spinner-border productlist-spinner">
                                <span className="sr-only" role="status"></span>
                            </div>
                        </div>}

                        {state.files.map((f, index) => {
                            return <Row key={index} className={index%2 ==0 ? 'productlist-alternate-row': ''} >
                                <Col className="productlist-name"><a href={`${f.url}?token=${user.token}`}>{f.fileName}</a></Col>
                                <Col xs={3}>{moment(f.lastModified).format('DD.MM.YYYY hh:mm')}</Col>
                                <Col className="productlist-size" xs={2}>{bytesToSize(f.size)}</Col>
                            </Row>
                        })}
                    </Container>
                </Form>
            </div>
        )
}