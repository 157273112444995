import React, { useContext } from 'react';
import { Login } from './components/Login';
import { ProductList } from './components/ProductList';
import { AuthContext,  } from './components/AuthContext'

import './custom.css'

const App = (props) => {
  const { user } = useContext(AuthContext)
  return (user.token ? <ProductList /> : <Login />)
}

export default App;