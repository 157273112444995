import React, { useCallback, useContext, useState } from 'react';
import { Container, Col, Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap';
import axios from 'axios'
import querystring from 'query-string'
import { AuthContext } from './AuthContext'
import './Login.css'

export const Login = (props) => {
    const [ isLoading, setIsLoading] = useState(false);
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loginError, setLoginError ] = useState(null);
    const { login, logout } = useContext(AuthContext);

    const onLoginClick = useCallback(event => {
        var expirationInMinutes = 60;
        var body = querystring.stringify({
            request: 'getToken',
            username: username,
            password: password,
            expiration: expirationInMinutes,
            f: 'json'
        });

        setIsLoading(true);
        axios.post("https://services.geodataonline.no/arcgis/tokens/", body, { 'Content-Type': 'application/x-www-form-urlencoded' })
            .then(response => {
                if (response.data.error) {
                    setIsLoading(false);
                    setLoginError("Feil brukernavn eller passord");
                    return;
                }

                var expiresInMilliseconds = response.data.expires - new Date().getTime()
                setTimeout(() => {
                    logout();
                }, expiresInMilliseconds)

                axios.get(`settings`, {
                    params: {
                        token: response.data.token
                    }
                })
                .then(({data}) => {
                    login(username, response.data.token, { categories: data.categories })
                }).catch(() => {
                    setIsLoading(false);
                    setLoginError("Det oppstod en feil, prøv igjen senere");
                })
            })
        }, [username, password, login, logout, setLoginError, setIsLoading])

    const onPasswordKeyDown = event => {
        if (event.key === "Enter") {
            onLoginClick()
        }
    }

    return (
    <Container className="login-container">
        <Col><h5>Logg inn med din Geodata Online-bruker</h5></Col>
        <Form className="login-form">
            <Col>
            <FormGroup>
                <Label for="username">Brukernavn</Label>
                <Input type="text" name="username" onChange={ (event) => setUsername(event.target.value)} invalid={loginError ? true : false} />
                <FormFeedback >{loginError}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="password">Passord</Label>
                <Input type="password" name="password" onChange={ (event) => setPassword(event.target.value)} onKeyDown={onPasswordKeyDown} />
            </FormGroup>
            </Col>
            <Col><Button onClick={onLoginClick}>
                    { isLoading && <span className="spinner-border spinner-border-sm login-spinner" role="status" aria-hidden="true"></span>}Logg inn
                </Button></Col>
        </Form>
    </Container>
    );
}
