import React from 'react'

const AuthContext = React.createContext()

const AuthProvider = (props) => {
    const [user, setUser] = React.useState({ name: null, token:null, settings: null })


    const login = (name, token, settings) => { setUser({name, token, settings }) }
    const logout = () => { setUser({ name: null, token: null, settings: null }) }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {props.children}            
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }